<template>
    <div style="width:100%">
        <div class="headerClass" v-if="isloading">
            <div class="mingalarparOuterClass">
                <div class="animation-layout">
                    <img height="80px" width="80px" class="mingalaparlogoClass" 
                      src="https://d3vsnl8idgwrlw.cloudfront.net/refugeeWelcomeLogo.svg " alt="Logo">
                </div>
                <div class="adminClass">
                  <p style="display: flex; font-size: x-large !important; font-weight: 700;" >Mingalarpar👋</p>
                </div>
            </div>
            <div class="logoOuterClass">
                <img class="mymedlogoClass" src="https://d3vsnl8idgwrlw.cloudfront.net/HBA_MyMedicineLogo.svg" alt="Logo">
            </div>
        </div>
    </div>
</template>

<script >

export default {
    name: 'HomePageTopBar',
    components: {

    },
    data() {
        return {

            isloading: true 
        }
    },
    async mounted() {
        const now = new Date();
        const hour = now.getHours();
        
    }
}
</script>

<style scoped>


.headerClass {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 16px 16px 16px;
  margin-left: 30px;
  margin-right: 30px;

  @media (min-width: 756px) and (max-width: 991px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
}
.mingalarparOuterClass {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 756px) and (max-width: 991px) {
    width: 30%;
    gap: 24px;
  }
  @media (max-width: 600px) {
    display: none;
  }
}
.animation-layout {
  color: #333;
  height: 96px;
  margin: 0;
  padding: 0;
  width: 100px;
  margin-top: 14px;

  @media (min-width: 756px) and (max-width: 991px) {}
}
.adminClass {
  display: flex;
  flex-direction: column;
}
.mingTxtClass {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;

  @media (min-width: 756px) and (max-width: 991px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.adminTxtClass {
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

  @media (min-width: 756px) and (max-width: 991px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.logoOuterClass {
  /* width: 30%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 12px;

  @media (min-width: 756px) and (max-width: 991px) {
    width: 24%;
    /* justify-content: flex-end; */
  }
  @media (max-width: 600px) {
    justify-content: center;
    flex: 1;
  }
}
.mymedlogoClass {
  @media (min-width: 756px) and (max-width: 991px) {
    width: 80px;
    height: 75px;
  }
}
.mingalaparlogoClass {
  @media (min-width: 756px) and (max-width: 991px) {
    width: 50px;
    height: 50px;
  }
}
</style>